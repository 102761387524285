<template>
  <div>
    <b-row class="mt-4">
      <b-col
        class="mt-1"
        cols="6"
      >
        {{
          isAmountTypeValue ? $t('views.bookings.index.discount.amount-label') : $t('views.bookings.index.discount.percentage-label')
        }}
      </b-col>
      <b-col cols="6">
        <div class="d-flex flex-row align-content-center align-items-center">
          <b-input-group>
            <b-form-input
              v-model="discount.amount"
              :max="isAmountTypeValue ? initialAmount : 100"
              :placeholder="$t('general.actions.numbers.zero')"
              type="number"
              size="sm"
              min="0"
              class="background-light-blue-inputs"
            >
            </b-form-input>
            <b-input-group-append is-text>
              <div>{{ isAmountTypeValue ? $currency : '%' }}</div>
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="6">
        {{ $t('views.bookings.index.discount.price') }}
      </b-col>
      <b-col cols="6" align="right" class="price-text">
        <div>
          {{ price }} {{ $currency }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {AMOUNT_TYPE} from "@/classes/doinsport/Discount";

export default {
  data: () => ({
    initialPrice: 0,
  }),
  props: {
    discount: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    discount: {
      deep: true,
      handler() {
        this.onAmountChange();
      }
    }
  },
  computed: {
    price() {
      return this.initialPrice;
    },
    isAmountTypeValue() {
      return this.discount.amountType === AMOUNT_TYPE;
    },
    initialAmount() {
      return this.getInitialAmount();
    },
  },
  methods: {
    getInitialAmount() {
      if (this.discount.initialPrice.toString().includes(this.$currency) || this.discount.initialPrice.toString().includes('%')) {
        return this.discount.initialPrice.toString().split(this.$currency)[0];
      } else {
        return this.discount.initialPrice;
      }
    },
    onAmountChange() {
      if (this.isAmountTypeValue) {
        this.initialPrice = this.initialAmount - this.discount.amount;
      } else {
        if (this.discount.amount !== 0) {
          this.initialPrice = this.initialAmount - (this.initialAmount * this.discount.amount) / 100;
        }
      }
    },
    initInitialPrice() {
      this.initialPrice = this.getInitialAmount();
    }
  },
  created() {
    this.initInitialPrice();
  }
}
</script>
<style lang="scss" scoped>
.price-text {
  font-weight: bold;
  font-size: 15px;
}

/deep/ .input-group-text {
  line-height: 0;
  color: #092772;
}

.custom-left-borders {
  border-bottom-left-radius: unset;
  border-top-left-radius: unset;
}

.custom-right-borders {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
</style>
